/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React from 'react';
import Scroll from 'react-scroll';
import { isAbsoluteUrl } from '../../../../../../helpers';

import './FestivalFooterNavigation.scss';

interface MenuItem {
  path: string;
  label: string;
  target?: string;
}

interface FestivalFooterNavigationProps {
  menuConfig: MenuItem[];
  showScrollToTop?: boolean;
}

export default function FestivalFooterNavigation({
  menuConfig,
  showScrollToTop,
}: FestivalFooterNavigationProps) {
  return (
    <nav className="c-festival-footer-navigation">
      <ul>
        {menuConfig.map(({ path, label, target }, index) => {
          const defaultTarget = isAbsoluteUrl(path) ? '_blank' : '_self';

          return (
            <li key={index}>
              <a
                href={path}
                target={target || defaultTarget}
                rel={isAbsoluteUrl(path) ? 'noopener noreferrer' : undefined}
              >
                {label}
              </a>
            </li>
          );
        })}
        {showScrollToTop && (
          <li>
            <span
              onClick={Scroll.animateScroll.scrollToTop}
              className="c-festival-footer-navigation__scroll-top-top"
            >
              Back to top <i className="fas fa-chevron-up" />
            </span>
          </li>
        )}
      </ul>
    </nav>
  );
}

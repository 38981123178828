import React from 'react';
import Utils from '../../../../../utils';
import { useRootSelector } from '../../../../../store/hooks';
import { selectPageConfig } from '../../../../../store/features/pageConfig';
import FestivalFooterCopyright from './FestivalFooterCopyright';
import FestivalFooterNavigation from './FestivalFooterNavigation';
import FestivalFooterSponsors from './FestivalFooterSponsors';
import FestivalFooterSocialNetwork from './FestivalFooterSocialNetwork';
import CallToAction from '../../../../shared/CallToAction/CallToAction';
import { contentConfig } from './constants';

import './FestivalFooter.scss';

export function FestivalFooter() {
  const { tenantConfig } = useRootSelector(selectPageConfig);
  const { copyright } = tenantConfig;

  const {
    hashTag,
    eventDates: { startDate },
    socialNetworkConfig,
    footerSection: {
      showFormattedDate,
      formattedDateFormat = 'MMM YYYY',
      customEventDatesText,
      showScrollToTop,
      registerInterestUrl,
      registerInterestText,
      menuConfig = [],
      sponsorsConfig = [],
    },
  } = contentConfig;

  return (
    <footer className="c-festival-footer">
      <div className="container">
        <div className="row">
          <div className="col-xs">
            <div className="c-festival-footer__top">
              {(showFormattedDate || hashTag) && (
                <div
                  className="c-festival-footer__festival-info"
                  data-testid="festival-info"
                >
                  {customEventDatesText && (
                    <div className="c-festival-footer__custom-dates-text">
                      {customEventDatesText}
                    </div>
                  )}
                  {showFormattedDate && (
                    <div
                      className="c-festival-footer__short-date"
                      data-testid="short-date"
                    >
                      {Utils.formattedTime(startDate, formattedDateFormat)}
                    </div>
                  )}
                  {hashTag && (
                    <div
                      className="c-festival-footer__hash-tag"
                      data-testid="hash-tag"
                    >
                      {hashTag}
                    </div>
                  )}
                </div>
              )}
              {sponsorsConfig.length > 0 && (
                <div className="c-festival-footer__sponsors">
                  <FestivalFooterSponsors config={sponsorsConfig} />
                </div>
              )}
            </div>
          </div>
        </div>
        {(showFormattedDate || hashTag) && <hr data-testid="festival-hr" />}
        <div className="c-festival-footer__bottom row">
          <div className="col-xs">
            <div className="c-festival-footer__social-network">
              <FestivalFooterSocialNetwork config={socialNetworkConfig} />
            </div>
            {registerInterestUrl && registerInterestText && (
              <div
                className="c-festival-footer__register-interest"
                data-testid="register-interest"
              >
                <CallToAction
                  to={registerInterestUrl}
                  label={registerInterestText}
                  rel="noopener noreferrer"
                />
              </div>
            )}
          </div>
          <div className="col-xs">
            <div className="c-festival-footer__navigation">
              <FestivalFooterNavigation
                menuConfig={menuConfig}
                showScrollToTop={showScrollToTop}
              />
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-xs">
            <div className="c-festival-footer__copyright">
              <FestivalFooterCopyright copyright={copyright} />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FestivalFooter;

import facebook from '../../../../../../assets/images/festivals/social/icon-facebook.png';
import twitter from '../../../../../../assets/images/festivals/social/icon-twitter.png';
import youtube from '../../../../../../assets/images/festivals/social/icon-youtube.png';
import linkedin from '../../../../../../assets/images/festivals/social/icon-linkedin.png';
import singapore from '../../../../../../assets/images/festivals/singapore-logo.svg';
import IMDA from '../../../../../../assets/images/festivals/IMDA-logo.png';
import informaTech from '../../../../../../assets/images/festivals/informa-tech-logo@2x.png';
import informaGlobal from '../../../../../../assets/images/informa-global.png';
import SECB from '../../../../../../assets/images/festivals/secb-logo.svg';

export const contentConfig = {
  hashTag: '#ATxSG',
  eventName: 'Asia Tech x Singapore',
  socialNetworkConfig: {
    menuConfig: [
      {
        path: 'https://www.facebook.com/AsiaTechxSG/',
        faIcon: 'fab fa-facebook-square fa-2x',
      },
      {
        path: 'https://www.linkedin.com/company/atxsg/',
        faIcon: 'fab fa-linkedin fa-2x',
      },
      {
        path: 'https://twitter.com/AsiaTechxSG/',
        faIcon: 'fab fa-square-x-twitter fa-2x',
      },
    ],
  },
  eventDates: {
    startDate: '2022-05-31',
    endDate: '2022-06-03',
    adminPanel: {
      minDate: '2022-05-31',
      maxDate: '2022-06-03',
      enable: [
        {
          from: '2022-05-31',
          to: '2022-06-03',
        },
      ],
    },
  },
  footerSection: {
    showFormattedDate: false,
    formattedDateFormat: 'YYYY',
    customEventDatesText: '27 - 29 May 2025',
    showScrollToTop: false,
    registerInterestText: '',
    registerInterestUrl: '',
    sponsorsConfig: [
      {
        heading: 'Held in',
        listing: [
          {
            logo: 'SINGAPORE',
            href: 'https://www.visitsingapore.com/',
            target: '_blank',
          },
        ],
      },
      {
        heading: 'Brought to you by',
        listing: [
          {
            logo: 'IMDA',
            href: 'https://www.imda.gov.sg/',
            target: '_blank',
          },
          {
            logo: 'INFORMA_GLOBAL',
            href: 'https://www.informa.com/',
            target: '_blank',
          },
        ],
      },
      {
        heading: 'Supported by',
        listing: [
          {
            logo: 'SECB',
            href: 'https://www.visitsingapore.com/mice/en/about-us/about-secb/',
            target: '_blank',
          },
        ],
      },
    ],
    menuConfig: [
      {
        path: 'https://www.informa.com/generic-content/cookie-policy/#:~:text=When%20you%20first%20visit%20the,Cookies%20at%20any%20later%20time',
        label: 'Cookies',
      },
      {
        path: 'https://privacyportal-eu-cdn.onetrust.com/dsarwebform/c1f53e84-9f05-4169-a854-85052b63c50b/942f64ed-e8f8-440a-b4b8-3f28c9c648f8.html',
        label: 'CPPA: Do not sell my personal information',
      },
      {
        path: 'https://www.informa.com/privacy-policy/',
        label: 'Privacy',
      },
      {
        path: 'https://asiatechxsg.com/terms-and-conditions/',
        label: 'Terms',
      },
      {
        path: 'https://asiatechxsg.com/event-announcements/ ',
        label: 'Press & Media',
      },
      {
        path: 'mailto:info@asiatechxsg.com?subject=ATX%20Contact%20Us%20Enquiry',
        label: 'Contact Us',
        target: '_blank',
      },
    ],
  },
};

export const socialNetworkIconsConfig: { [key: string]: string } = {
  facebook,
  twitter,
  youtube,
  linkedin,
};

interface SponsorConfig {
  alt: string;
  src: string;
}

interface SponsorsAndPartnersConfig {
  [key: string]: SponsorConfig;
}

export const sponsorsAndPartnersConfig: SponsorsAndPartnersConfig = {
  SINGAPORE: {
    alt: 'Singapore Passion Made Possible',
    src: singapore,
  },
  IMDA: {
    alt: 'Infocomm media development authority',
    src: IMDA,
  },
  INFORMA_TECH: {
    alt: 'Informa Tech',
    src: informaTech,
  },
  INFORMA_GLOBAL: {
    alt: 'Informa',
    src: informaGlobal,
  },
  SECB: {
    alt: 'Singapore Exhibition & Convention Bureau',
    src: SECB,
  },
};

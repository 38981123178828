import React from 'react';
import { HTMLtoJSX } from '../../../../../../helpers';
import s from './index.module.scss';

type FestivalFooterCopyrightProps = {
  copyright: string;
};

export default function FestivalFooterCopyright(
  props: FestivalFooterCopyrightProps,
) {
  return <div className={s.wrapper}>{HTMLtoJSX(props.copyright)}</div>;
}

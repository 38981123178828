import React from 'react';
import styled from 'styled-components';
import ImageTag from '../../../../../shared/ImageTag/ImageTag';
import { socialNetworkIconsConfig } from '../constants';
import './FestivalFooterSocialNetwork.scss';

const FAIcon = styled.i`
  color: #ffffff;
`;

interface MenuItem {
  path: string;
  icon?: string;
  faIcon?: string;
}

interface FestivalFooterSocialNetworkProps {
  config: {
    heading?: string;
    menuConfig?: MenuItem[];
  };
}

export default function FestivalFooterSocialNetwork(
  props: FestivalFooterSocialNetworkProps,
) {
  const {
    config: { heading = '', menuConfig = [] },
  } = props;

  return (
    <nav className="c-festival-social-network">
      {heading && (
        <h4 className="c-festival-social-network__heading">{heading}</h4>
      )}
      <ul>
        {menuConfig.map(({ path, icon, faIcon }, index) => (
          <li key={index}>
            <a href={path} target="_blank" rel="noopener noreferrer">
              {faIcon ? (
                <FAIcon className={faIcon} />
              ) : (
                <ImageTag
                  src={socialNetworkIconsConfig[icon as string]}
                  alt={icon}
                  imgix={false}
                  lazy={false}
                  style={{ height: '40px' }}
                />
              )}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
}

import React from 'react';
import ImageTag from '../../../../../shared/ImageTag/ImageTag';
import { sponsorsAndPartnersConfig } from '../constants';
import './FestivalFooterSponsors.scss';

interface Listing {
  logo: string;
  href: string;
  target?: string;
}

interface ConfigItem {
  heading?: string;
  listing?: Listing[];
}

interface FestivalFooterSponsorsProps {
  config: ConfigItem[];
}

export default function FestivalFooterSponsors({
  config,
}: FestivalFooterSponsorsProps) {
  return (
    <div className="c-festival-footer-sponsors">
      {config.map(({ heading, listing = [] }, index) => (
        <div key={index} className="c-festival-footer-sponsors__category">
          {heading && (
            <div className="c-festival-footer-sponsors__heading">{heading}</div>
          )}
          <div className="c-festival-footer-sponsors__listing">
            {listing.map(({ logo, href, target }) => {
              const { alt, src } = sponsorsAndPartnersConfig[logo];

              return (
                <div key={logo} className="c-festival-footer-sponsors__logo">
                  <a href={href} target={target}>
                    <ImageTag alt={alt} src={src} imgix={false} lazy={false} />
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
}
